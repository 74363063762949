import { useContext, useEffect, useState } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { AppContext } from "../app-context";
import "../styles/globals.scss";
import styles from "../styles/compare.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import CompareSearch from "./CompareSearch";
import { useListNodeService } from "../utilities/apiService";
import AlertPopUp from "./AlertPopup";

const Compare = () => {
  /**
   * Variables and context section
   */
  const context = useContext(AppContext);
  const [nodes, setNodes] = useState<any>([]);
  const callListNodesAPI = useListNodeService();

  // Delete the nodes in the compare page
  const deleteCompareNode = (element: any) => {
    const deletedItem = element;
    context.setCompareNodeData(
      context.compareNodeData.filter((item: any) => {
        return item !== deletedItem;
      })
    );
  };
  // {context && context.nodeActionPopupFlag && (
  //   <div className={fcCSS.frostedGlassWhiteLiteBackDrop}></div>
  // )}
  const renderSymbol = (symbol: string) => {
    switch (symbol) {
      case "oval":
        return "circle";
      case "triangle":
        return "change_history";
      case "rectangle":
        return "square";
      default:
        return symbol;
    }
  };
  useEffect(() => {
    context.setSideMenuBar(false);
    context.setNodeActionPopupFlag(false);
    context.setIsSearchBoxOpen(false);
    context.setExpandAllNodes(false);
    try {
      const params = {
        owner_uu_ad_id: context.userToken.account.localAccountId,
        owner_email: context.userToken.account.userName,
        owner_name: context.userToken.account.name,
        // tree_type: context.treeType,
      };
      (async () => {
        const api: any = await callListNodesAPI(params);
        setNodes(api.data.all_nodes);
      })();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <div
          className={[
            fcCSS.posFixed,
            context && context.nodeActionPopupFlag
              ? fcCSS.frostedGlassWhiteLiteBackDrop
              : "",
          ].join(" ")}
        ></div>
        <div className={["rootWrapper", styles.compareBg].join(" ")}>
          <Header />
          {context.sideMenuBar && <SideMenu />}
          <div className={[styles.parent, fcCSS.posSticky].join(" ")}>
            <div className={["pageTitleBar", fcCSS.boxShadowDown].join(" ")}>
              <div className={fcCSS.section1280}>
                <div
                  className={[fcCSS.flex, fcCSS.flexAlignItemsCenter].join(" ")}
                >
                  <h3>Compare Metrics</h3>
                </div>
              </div>
            </div>
            <div
              className={[fcCSS.section1280, styles.bdrCompareTable].join(" ,")}
            >
              <div
                className={[
                  styles.compareTable,
                  fcCSS.horizontalScrollContainer,
                ].join(" ")}
              >
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Name
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <div className={styles.compareCell}>
                        <div
                          className={[
                            fcCSS.fontWeight700,
                            fcCSS.flex,
                            fcCSS.flexDirectionCol,
                            fcCSS.flexJustifyBetween,
                            fcCSS.height100p,
                          ].join(" ")}
                        >
                          <div
                            className={[
                              fcCSS.flex,
                              fcCSS.flexAlignItemsCenter,
                            ].join(" ")}
                          >
                            <span
                              className={[
                                fcCSS.mrgnright5,
                                "material-symbols-rounded icons",
                              ].join(" ")}
                            >
                              {renderSymbol(item.node_symbol)}
                            </span>
                            {item.node_name}
                          </div>
                          <button
                            className={[
                              styles.bgLessRedBtn,
                              fcCSS.width100,
                              fcCSS.txtCenter,
                            ].join(" ")}
                            onClick={() => deleteCompareNode(item)}
                          >
                            <span
                              className={[
                                "material-symbols-rounded icons",
                              ].join()}
                            >
                              delete
                            </span>
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>
                      <button
                        className={styles.bgLessBlueBtnLarge}
                        onClick={() => {
                          context.setNodeActionPopupFlag(
                            !context.nodeActionPopupFlag
                          );
                          context.setSideMenuBar(false);
                        }}
                      >
                        <span className="material-symbols-rounded icons">
                          add
                        </span>
                        Add to Compare
                      </button>
                    </div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Level
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <div className={styles.compareCell}>{item.level}</div>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Link
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <>
                        <div className={styles.compareCell}>
                          {item.dashboard_link === null ||
                          item.dashboard_link === "" ? (
                            "NA"
                          ) : (
                            <div>
                              {item.dashboard_link.startsWith("https://") ||
                              item.dashboard_link.startsWith("http://") ? (
                                <div>
                                  <a
                                    href={item.dashboard_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Dashboard Link
                                    <span
                                      className={[
                                        fcCSS.mrgnleft5,
                                        "material-symbols-rounded icons",
                                      ].join(" ")}
                                    >
                                      link
                                    </span>
                                  </a>
                                </div>
                              ) : (
                                <div>
                                  <div
                                    className={[styles.clipBoard].join(" ")}
                                    onClick={() => {
                                      navigator.clipboard
                                        .writeText(item.dashboard_link)
                                        .then(
                                          () => {
                                            context.setAlertData({
                                              message:
                                                "This dashboard is located on Niagara's Shared Drive. The path is now copied to your clipboard. Once you are connected to the Niagara network, you may paste this path into your file explorer to access it.",
                                              statusCode: 200,
                                              time: 8000,
                                            });
                                            context.setIsAlertPopUp(true);
                                            // alert(
                                            //   "This dashboard is located on Niagara's Shared Drive. The path is now copied to your clipboard. Once you are connected to the Niagara network, you may paste this path into your file explorer to access it."
                                            // );
                                          },
                                          () => {}
                                        );
                                    }}
                                  >
                                    Copy to clipboard
                                    <span
                                      className={[
                                        fcCSS.mrgnleft5,
                                        "material-symbols-rounded icons",
                                      ].join(" ")}
                                    >
                                      content_copy
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Custodian
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    const ownerEmail =
                      item.assigned_to !== null ? item.assigned_to : null;
                    return (
                      <div className={styles.compareCell}>
                        {ownerEmail !== null ? ownerEmail : "NA"}
                      </div>
                    );
                  })}

                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Sharepoint Link
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <div className={styles.compareCell}>
                        {item.sharepoint_link === null ||
                        item.sharepoint_link === "" ? (
                          "NA"
                        ) : (
                          <a
                            href={item.sharepoint_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            SharePoint Link
                            <span
                              className={[
                                fcCSS.mrgnleft5,
                                "material-symbols-rounded icons",
                              ].join(" ")}
                            >
                              link
                            </span>
                          </a>
                        )}
                      </div>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Department
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <>
                        <div className={styles.compareCell}>
                          {item.dependant_departments.length > 0 ? (
                            item.dependant_departments.map(
                              (ele: any, index: any) => {
                                return (
                                  <>
                                    {ele.name}
                                    {item.dependant_departments.length >
                                    index + 1
                                      ? " , "
                                      : " "}
                                  </>
                                );
                              }
                            )
                          ) : (
                            <div>NA</div>
                          )}
                        </div>
                      </>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Path
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <div className={styles.compareCell}>
                        {item.parent_path.length > 0 ? (
                          item.parent_path.map((e: any, index: any) => {
                            return (
                              <>
                                {e.node_name}
                                {item.parent_path.length > index + 1
                                  ? " , "
                                  : " "}
                              </>
                            );
                          })
                        ) : (
                          <div>NA</div>
                        )}
                      </div>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Next Layers
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <div className={styles.compareCell}>
                        {item.child_path.length > 0 ? (
                          item.child_path.map((e: any, index: any) => {
                            return (
                              <>
                                {e.node_name}
                                {item.child_path.length > index + 1
                                  ? " , "
                                  : " "}
                              </>
                            );
                          })
                        ) : (
                          <div>NA</div>
                        )}
                      </div>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
                <div className={styles.compareRow}>
                  <div
                    className={[
                      styles.compareCell,
                      styles.compareHeadingCell,
                      styles.compareColumnFixed,
                    ].join(" ")}
                  >
                    Additional Information
                  </div>
                  {context.compareNodeData?.map((item: any) => {
                    return (
                      <div className={styles.compareCell}>
                        {item.additional_information === "" ||
                        item.additional_information === null
                          ? "NA"
                          : item.additional_information}
                      </div>
                    );
                  })}
                  {context.compareNodeData.length < 5 && (
                    <div className={styles.compareCell}>NA</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {context.nodeActionPopupFlag ? (
            <div className={[styles.addComparePopUp].join(" ")}>
              <CompareSearch nodes={nodes} />
            </div>
          ) : null}
        </div>
        {context.isAlertPopUp && <AlertPopUp />}
      </div>
    </>
  );
};

export default Compare;
