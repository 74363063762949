import styles from "../styles/sideMenu.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import { NavLink } from "react-router-dom";
import { useLogout } from "../utilities/authService";
import { useActivityLogService } from "../utilities/apiService";
import { useContext } from "react";
import { AppContext } from "../app-context";
const SideMenu = () => {
  /**
   * Custom Hook and context section
   */
  const context = useContext(AppContext);
  const callActivityLogAPI = useActivityLogService();
  const logout = useLogout();
  /**
   * _handleLogout function definition section
   * This function helps us to logout the application
   */
  const _handleLogout = () => {
    callActivityLogAPI("logged_out").then((resp: any) => {
      if (resp?.status) {
        logout();
      }
    });
  };

  return (
    <div
      className={[styles.sideMenuContainer, fcCSS.frostedGlassWhiteLiteBg].join(
        " "
      )}
    >
      <div>
        <NavLink
          to="/dashboard"
          className={({ isActive, isPending }) =>
            styles.menuItem + " " + (isActive ? styles.active : "")
          }
        >
          <span className="material-symbols-rounded icons">dashboard</span>
          <div className={[styles.menuText].join(" ")}>Dashboard</div>
        </NavLink>
        {context.isAdmin && (
          <NavLink
            to="/activities/graph"
            className={({ isActive, isPending }) =>
              styles.menuItem + " " + (isActive ? styles.active : "")
            }
          >
            <span className="material-symbols-rounded icons">
              browse_activity
            </span>
            <div className={[styles.menuText].join(" ")}>Activity Tracker</div>
          </NavLink>
        )}
        <NavLink
          to="/compare"
          className={({ isActive, isPending }) =>
            styles.menuItem + " " + (isActive ? styles.active : "")
          }
        >
          <span className="material-symbols-rounded icons">Compare</span>
          <div className={[styles.menuText].join(" ")}>Compare</div>
        </NavLink>
        {/* <NavLink
          to="/settings"
          className={({ isActive, isPending }) =>
            styles.menuItem + " " + (isActive ? styles.active : "")
          }
        >
          <span className="material-symbols-rounded icons">settings</span>
          <div className={[styles.menuText].join(" ")}>Settings</div>
        </NavLink>  */}
      </div>

      <div className={[styles.logoutBtnParent].join(" ")}>
        <button
          onClick={_handleLogout}
          className={[styles.logoutBtn].join(" ")}
        >
          Logout
        </button>
      </div>
    </div>
  );
};
export default SideMenu;
