import { useContext } from "react";
import { AppContext } from "../app-context";
import fcCSS from "../styles/flexcssable.module.scss";
import "../styles/globals.scss";
import styles from "../styles/compare.module.scss";
import searchStyles from "../styles/search.module.scss";
const CompareSearch = (nodes: any) => {
  /**
   * Variables and context section
   */
  let searchTextEle: any;
  const context = useContext(AppContext);
  /**
   * Filter nodes function definition section
   * This function helps us to filter nodes based on node names
   */
  const filterNodes = () => {
    context.setCompareListingNodes(
      // eslint-disable-next-line
      nodes?.nodes?.filter((item: any) => {
        if (typeof item !== "undefined" && item.parent_path.length > 0) {
          if (context.compareNodeData.length > 0) {
            if (
              item.node_name
                .toLowerCase()
                .includes(searchTextEle?.value.toLowerCase()) &&
              !context.compareNodeData.some(
                (element: any) => element._id === item._id
              )
            ) {
              return item;
            }
          } else {
            if (
              item.node_name
                .toLowerCase()
                .includes(searchTextEle?.value.toLowerCase())
            ) {
              return item;
            }
          }
        }
      })
    );
  };

  const _handleCompareButton = (item: any) => {
    //To avoid duplication
    const isExisting = context.compareNodeData.some(
      (existingItem: any) => existingItem._id === item._id
    );

    if (isExisting) {
      // alert("Node already exists");
      context.setAlertData({
        message: "Node already exists",
        statusCode: 201,
      });
      context.setIsAlertPopUp(true);
    } else {
      context.setCompareNodeData((prev: any) => [...prev, item]);
      localStorage.setItem(
        "compareData",
        JSON.stringify(context.compareNodeData)
      );

      context.setNodeActionPopupFlag(false);
    }
    context.setCompareListingNodes([]);
  };

  /**
   * onSearchInputChange and onDepartmentChange function definition section
   * These functions help us to collect search text and departments
   * @param event
   */
  const onSearchInputChange = (event: any) => {
    if (searchTextEle.value !== "") {
      filterNodes();
    } else {
      _handleReset();
    }
  };

  /**
   * cancelFunction function definition section
   * This function basically doing  toggle action based on UI cancel action
   */
  const cancelFunction = () => {
    context.setNodeActionPopupFlag(!context.nodeActionPopupFlag);
    _handleReset();
  };
  /**
   * Search input field reset function definition section
   */
  const _handleReset = () => {
    context.setCompareListingNodes([]);
    if (typeof searchTextEle !== "undefined") {
      searchTextEle.value = "";
    }
  };
  /**
   * renderList function definition section
   * This function helps us to create a node list UI based on search results
   * @returns node list
   */
  const renderList = () => {
    return context.compareListingNodes?.map((item: any, index: any) => (
      <>
        <article key={`card-` + index} className={searchStyles.nodeSearchCard}>
          <div className={searchStyles.sectionGroup}>
            <div className={styles.renderListTitle}>
              <i
                className={[
                  fcCSS.icns,
                  fcCSS.d25,
                  fcCSS.mrgnright5,
                  fcCSS.flexNoShrink,
                  "icon-" + item.node_symbol,
                ].join(" ")}
              ></i>
              <p>{item.node_name}</p>
            </div>
          </div>
          <div className={searchStyles.nodeSearchCardBody}>
            <label>Path:</label>
            <div className={[styles.layerBdr].join(" ")}>
              {item.parent_path?.map((element: any, index: any) => (
                <span key={`parent-` + index}>
                  <span onClick={(event) => {}}>{element.node_name}</span>
                  {item.parent_path.length > index + 1 && (
                    <span
                      className={[
                        searchStyles.caret,
                        searchStyles.iconPath,
                        "icon-path",
                      ].join(" ")}
                    ></span>
                  )}
                </span>
              ))}
            </div>

            <br />
            {item.child_path.length > 0 && (
              <>
                <label>Next Layers ({item.child_path.length}):</label>

                <div className={[styles.layerBdr].join(" ")}>
                  {item.child_path.map((a: any, index: any) => (
                    <span key={`child-` + index}>
                      <span>{a.node_name}</span>
                      {item.child_path.length > index + 1 && (
                        <span className={searchStyles.caret}>
                          &nbsp;|&nbsp;
                        </span>
                      )}
                    </span>
                  ))}
                </div>
              </>
            )}
            <button
              className={styles.bgLessBlueBtnSmall}
              onClick={() => _handleCompareButton(item)}
            >
              Add to Compare
            </button>
          </div>
        </article>
      </>
    ));
  };

  return (
    <div className={styles.addComparePopupContainer}>
      <div className={[styles.addComparePopupHeader].join(" ")}>
        <div className={styles.title}>
          <h1>Add to Compare</h1>
          <span
            className={[
              "material-symbols-rounded",
              "icons",
              fcCSS.cursorPtr,
            ].join(" ")}
            onClick={cancelFunction}
          >
            close
          </span>
        </div>
        <div
          className={[searchStyles.sectionGroup, styles.textInput].join(" ")}
        >
          <input
            ref={(el) => (searchTextEle = el)}
            type="text"
            className={"input"}
            name="search"
            placeholder="Search"
            onChange={(event) => onSearchInputChange(event)}
            autoFocus
            autoComplete="off"
          />
        </div>
      </div>
      <div
        className={[
          fcCSS.width100,
          fcCSS.verticalScrollContainer,
          fcCSS.frostedGlassWhiteLiteBg,
          fcCSS.pad10,
        ].join(" ")}
      >
        {renderList()}
      </div>
    </div>
  );
};

export default CompareSearch;
