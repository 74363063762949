import { useContext } from "react";
import { AppContext } from "../app-context";
import styles from "../styles/legendsList.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import { FilledCircle } from "./common/SvgDynamic";
function LegendsList() {
  /**
   * context section
   */
  const context = useContext(AppContext);
  /**
   * cancelFunction function definition section
   * This function help us to set a LegendsListClose context value as true or false.
   */
  function cancelFunction() {
    context.setLegendsListClose(context.legendsListClose ? false : true);
  }

  return (
    <div className={styles.legendsList}>
      {context.legendsListClose && (
        <div
          className={[
            styles.legendsListContainer,
            fcCSS.frostedGlassBlackDarkBg,
          ].join(" ")}
        >
          <div
            className={[
              styles.legendContainer,
              fcCSS.flex,
              fcCSS.flexJustifyBetween,
              fcCSS.flexAlignItemsStart,
            ].join(" ")}
          >
            <div className={[styles.legendsListItem].join(" ")}>
              <div className={styles.title}>Type of Data</div>
              <br></br>
              <div className={styles.shapeContainer}>
                <div>
                  <span className="material-symbols-rounded icons">
                    diamond
                  </span>
                </div>
                <div>KPI</div>
              </div>
              <div className={styles.shapeContainer}>
                <div>
                  <span className="material-symbols-rounded icons">square</span>
                </div>
                <div>Metric</div>
              </div>
              <div className={styles.shapeContainer}>
                <div>
                  <span className="material-symbols-rounded icons">
                    change_history
                  </span>
                </div>
                <div>Category</div>
              </div>
              <div className={styles.shapeContainer}>
                <div>
                  <span className="material-symbols-rounded icons">circle</span>
                </div>
                <div>Data Point</div>
              </div>
            </div>
            <div
              className={[styles.verticalHr, fcCSS.flexAlignSelfStretch].join(
                " "
              )}
            ></div>
            <div className={styles.legendsListItem}>
              <div className={styles.title}>Departments</div>
              <br></br>
              {context?.settings?.departments?.length > 0 && (
                <>
                  {context?.settings?.departments?.map(
                    (department: any, index: any) => (
                      <div
                        key={"legend-department-" + index}
                        className={styles.shapeContainer}
                      >
                        <div>
                          <FilledCircle color={department.color} size="large" />
                        </div>
                        <div className={styles.padtop5}>{department.name}</div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </div>
          <i
            className={[
              fcCSS.icns,
              fcCSS.d15,
              fcCSS.posAbsolute,
              fcCSS.posTop10px,
              fcCSS.posRight10px,

              "icon-cancel",
            ].join(" ")}
            onClick={cancelFunction}
            id="legendsClose"
          ></i>
        </div>
      )}
    </div>
  );
}

export default LegendsList;
