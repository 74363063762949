import { useState, useEffect, useContext } from "react";
import { AppContext } from "../app-context";
import { postAxios, putAxios } from "../api/axios-config";
import { nodeOptions } from "./common/Constants";
import { cancelFunction } from "../utilities/helper";
import {
  getDepartmentsOpts,
  getParentPath,
  parentPathOptions,
} from "../api/api-common";
import { MultiSelectDropdown, SelectDropdown } from "./common/select-dropdown";
import { InputField } from "./common/input";
import styles from "../styles/addNode.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import { DropdownIndicator } from "./common/SvgDynamic";
import { apiParams, apiUrl } from "../api/api-constants";
import { TextAreaField } from "./common/text-area";
function NodeDetailsForm({ setEditNode, isEdit }: any) {
  /**
   * State variable and Context section
   */
  const context = useContext(AppContext);
  const { nodeData } = context || {};
  const [node, setNode] = useState(isEdit ? nodeData : { owner: {} });
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentName, setDepartmentName] = useState<string[]>([]);
  const [apiStatus, setApiStatus] = useState(false);
  const [parentNodeInfo, setParentNodeInfo] = useState<any>({});
  let selectedOption = "null";
  let actionName = isEdit ? "Edit" : "Add";
  /**
   * _handleFormSubmit function definition section
   * This function helps us to submit the new/updated node information to DB
   * Here we calling update node api
   * @param event
   */
  const _handleFormSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      node_name: node.node_name?.trim()?.length ? node.node_name : "",
      dependant_departments: node.dependant_departments?.length
        ? node.dependant_departments
        : [],
      node_type: node.node_type,
      node_symbol: node.node_symbol,
      dashboard_link: node.dashboard_link || "",
      additional_information: node.additional_information || "",
      is_enabled: 1,
      file: {
        name: node.file?.name || "",
        url: node.file?.url || "",
      },
      share_point: node.share_point || "",
      assigned_to: node.assigned_to,
      tree_type: context.treeType,
      level: node.level,
    };
    try {
      let payload = isEdit
        ? { ...data, _id: nodeData["_id"], parent_id: node.parent_id }
        : {
            ...data,
            parent_id: nodeData["_id"],
            level: Number(nodeData.level) + 1,
          };
      let params = {
        code: apiParams.codeNode,
      };
      const apiRes: any = isEdit
        ? await putAxios(
            apiUrl.node,
            params,
            payload,
            context.userToken.accessToken
          )
        : await postAxios(
            apiUrl.node,
            params,
            payload,
            context.userToken.accessToken
          );
      setApiStatus(apiRes.data.status);
      context.setAlertData(apiRes.data);
      context.setIsAlertPopUp(true);
    } catch (error: any) {
      context.setAlertData({ message: "Error", statusCode: 400 });
      context.setNodeActionPopupFlag(false);
    }
  };
  /**
   * Based on updated node api status, we're doing node action popup hide/show
   */
  if (apiStatus) {
    context.setNodeActionPopupFlag(false);
    context.setIsPageReload(true);
    // context.setExpandAllNodes(false);
    // context.setSearchListingNodes([]);
    // context.setNodeLevel(0);
    context.setHighlightNode("");
    // context.setIsSearchBoxOpen(false);
  }
  /**
   * cancelPopup function definition section
   * This function helps us to hide the node action popup
   */
  function cancelPopup() {
    cancelFunction("edit", setEditNode, context);
  }
  /**
   * _handleDepartmentIds function definition definition section
   * This function helps us to set departments' values based on input (the user can choose one or more than one department and we get those inputs).
   * @param event
   */
  const _handleDepartmentIds = (event: any) => {
    setDepartmentName(event);
    let departmentIds: any = [];
    departmentIds = event.map((e: any) => e.value);
    setNode({
      ...node,
      dependant_departments: departmentIds,
    });
  };
  /**
   * _setInitialExistingDepartmentNames function definition section
   * This function helps us to set the existing selected departments to "setDepartmentName" state variable
   */
  const _setInitialExistingDepartmentNames = () => {
    let existingDptNames: any = [];
    nodeData.dependant_departments.forEach((item: any) =>
      existingDptNames.push({ label: item.name, value: item._id })
    );
    setDepartmentName(existingDptNames);
  };
  /**
   * onChange function definition section
   * This function helps us to watch and set the user selected/entered values to "setNode" state variable
   * @param event
   * @param field
   * @returns
   */
  const onChange = (event: any, field?: string) => {
    const { value, symbol } = event || {};
    if (field === "node_type") {
      const data = {
        [field]: value,
        node_symbol: symbol,
      };
      setNode({ ...node, ...data });
      return;
    } else if (field === "assigned_to") {
      const data = { [field]: event };
      setNode({ ...node, ...data });
      return;
    } else if (field === "dependant_departments") {
      _handleDepartmentIds(event);
      return;
    } else if (field === "node_name") {
      const data = {
        [field]: event,
      };
      setNode({ ...node, ...data });
      return;
    } else if (field === "dashboard_link") {
      const data = {
        [field]: event,
      };
      setNode({ ...node, ...data });
      return;
    } else if (field === "additional_information") {
      const data = {
        [field]: event,
      };
      setNode({ ...node, ...data });
      return;
    } else if (field === "parent_id") {
      const data = {
        [field]: event.value,
      };
      setNode({ ...node, ...data });
    }
  };
  /**
   * UseEffect section
   */
  useEffect(() => {
    /**
     * Here we fetch the user information list (graph api) with the help of the userList function.
     * Setting response to setAssignToOptions context variable
     * Setting department names to setDepartmentOptions state variable for department drop down field
     */
    (async () => {
      setDepartmentOptions(getDepartmentsOpts(context.settings.departments));
    })();
    isEdit && _setInitialExistingDepartmentNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.departmentList]);
  useEffect(() => {
    setParentNodeInfo(
      getParentPath(node.parent_id, context.nodeApiResponse.all_nodes)
    );
    // eslint-disable-next-line
  }, [node.parent_id]);
  return (
    <>
      <form
        id="popupAnimation"
        onSubmit={_handleFormSubmit}
        className={styles.addNodeForm}
      >
        <div className={styles.actionDiv}>
          <h2 className={styles.addNode}>{actionName}</h2>
          <span
            className={[
              "material-symbols-rounded",
              "icons",
              fcCSS.cursorPtr,
            ].join(" ")}
            onClick={cancelPopup}
          >
            close
          </span>
        </div>
        <div className={[fcCSS.pad10].join(" ")}>
          <InputField
            id="node-name"
            labelText="Name:"
            iType="text"
            value={node.node_name}
            placeholder={node.node_name || "Enter Name"}
            onChange={(event: any) => onChange(event, "node_name")}
            styles={styles}
          />
          {isEdit && (
            <>
              <SelectDropdown
                id="parent-node"
                labelText="Parent:"
                defaultValues={selectedOption}
                options={parentPathOptions(
                  node._id,
                  context.nodeApiResponse.all_nodes
                )} //nodeOptions
                onChange={(event: any) => {
                  onChange(event, "parent_id");
                }}
                placeholder={parentNodeInfo.patentName}
                styles={styles}
                dropDownArrow={{ DropdownIndicator }}
              />
              <div className={[styles.parentPathCards].join()}>
                <label>Parent Path:</label>
                <div className={[fcCSS.wrdBreakAll].join(" ")}>
                  {parentNodeInfo?.parentPath &&
                    parentNodeInfo?.parentPath[0]?.map((a: any, index: any) => (
                      <span key={`parent-` + index}>
                        <span>{a.node_name}</span>
                        {parentNodeInfo?.parentPath[0].length > index + 1 && (
                          <span
                            className={[
                              styles.caret,
                              styles.iconPath,
                              "icon-path",
                            ].join(" ")}
                          ></span>
                        )}
                      </span>
                    ))}
                </div>
              </div>
            </>
          )}
          <MultiSelectDropdown
            id="department"
            labelText="Department:"
            defaultValues={departmentName}
            options={departmentOptions}
            onChange={(event: any) => {
              onChange(event, "dependant_departments");
            }}
            placeholder="Select Department"
            styles={styles}
          />

          <SelectDropdown
            id="node-type"
            labelText="Type:"
            defaultValues={selectedOption}
            options={nodeOptions}
            onChange={(event: any) => {
              onChange(event, "node_type");
            }}
            placeholder={node.node_type || "Select Type"}
            styles={styles}
            dropDownArrow={{ DropdownIndicator }}
          />
          <InputField
            id="assigned-to"
            labelText="Custodian:"
            iType="text"
            value={node.assigned_to}
            placeholder={node.assigned_to || "Select User"}
            onChange={(event: any) => onChange(event, "assigned_to")}
            styles={styles}
          />

          <InputField
            id="dashboard-link"
            labelText="Dashboard Link:"
            iType="text"
            value={node.dashboard_link}
            placeholder={node.dashboard_link || "Past the link here"}
            onChange={(event: any) => onChange(event, "dashboard_link")}
            styles={styles}
          />

          <TextAreaField
            id="additional-info"
            labelText="Additional Information:"
            value={node.additional_information}
            placeholder={node.additional_information || "Enter here"}
            onChange={(event: any) => onChange(event, "additional_information")}
            styles={styles}
          />
        </div>
        <div className={styles.buttonDiv}>
          <button
            type="button"
            onClick={cancelPopup}
            className={styles.buttonDiscard}
          >
            Discard
          </button>
          <button className={styles.buttonChange}>Save Changes</button>
        </div>
      </form>
    </>
  );
}

export default NodeDetailsForm;
