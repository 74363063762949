/**
 * cancelFunction function definition section
 * This is a common function and this function helps us to cancel/close the popup's in our application
 * @param type
 * @param setState
 * @param context
 * @param marginBottom
 */
export function cancelFunction(
  type: string,
  setState: (arg0: boolean) => void,
  context: { setNodeActionPopupFlag: (arg0: boolean) => void },
  marginBottom?: string
) {
  const elementId = document.getElementById("popupAnimation");
  if (elementId) {
    elementId.classList.add("actionPopup");
    if (marginBottom) elementId.style.marginBottom = marginBottom;
    elementId.style.animationDuration = "0.5s";
    if (type !== "popup") {
      elementId.style.top = "50%";
      elementId.style.transition = "transform 0.5s ease-out";
    } else if (type === "popup") {
      elementId.style.transition = "none";
    }
    elementId.style.transform = "translateY(100%)";
    setTimeout(() => {
      setState(false);
      context.setNodeActionPopupFlag(false);
    }, 100);
  }
}
