import { useContext } from "react";
import fcCSS from "../styles/flexcssable.module.scss";
import { AppContext } from "../app-context";

const Loader = () => {
  const context = useContext(AppContext);

  return (
    context.appLoader && (
      <div
        className={["loaderContainer", fcCSS.frostedGlassWhiteLiteBg].join(" ")}
      >
        <div className="loaderImg"></div>
        <div className="loaderText">Loading</div>
      </div>
    )
  );
};
export default Loader;
