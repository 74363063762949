import Select from "react-select";
import { TickMark } from "./common/SvgDynamic";
import styles from "../styles/customNode.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
/**
 * DropdownIndicator function definition and export section
 * @returns svg drop down icon
 */
const DropdownIndicator = () => {
  return (
    <div className={styles.dropdownIcon}>
      <i className={[fcCSS.icns, fcCSS.d25, "icon-dropdown-arw"].join(" ")}></i>
    </div>
  );
};
const NodeStatus = (props: any) => {
  const { preventClick, selectedOption, dropdownComponent, handleOptionClick } =
    props;
  return (
    <>
      <div className={styles.horizontal}></div>
      <h2 className={styles.nodeStatus}>Status:</h2>
      <div className={fcCSS.width100} onClick={preventClick}>
        <Select
          defaultValue={selectedOption}
          className={[styles.dropdown, fcCSS.width100].join(" ")}
          styles={{
            indicatorSeparator: () => ({ display: "none" }),
            option: (state: any) => ({
              backgroundColor: "#FFFFFF",
              border: "1px solid #BFBFBF",
              padding: "4px",
              borderRadius: "12px",
              height: "40px",
              color: state.selectedOption ? "#ffffff" : "#333333",
              ":hover": {
                backgroundColor: state.selectedOption ? "#007bff" : "#f0f0f0",
              },
            }),
            control: () => ({
              height: "30px",
            }),
          }}
          placeholder={selectedOption}
          components={{ DropdownIndicator }}
        />

        {dropdownComponent && (
          <div className={styles.menuContainer}>
            {["Pending", "In Progress", "Completed"].map(
              (item: any, index: any) => (
                <div
                  key={index}
                  className={[styles.option].join(" ")}
                  style={
                    item === "Pending"
                      ? { color: "#2E2E2E" }
                      : item === "In Progress"
                      ? { color: "#D2BE06" }
                      : { color: "#1DAC56" }
                  }
                  onClick={() => handleOptionClick(item)}
                >
                  {item}
                  {selectedOption === item && <TickMark color="#2E2E2E" />}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NodeStatus;
