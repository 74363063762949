import { useState, useContext } from "react";
import DeleteNode from "./DeleteNode";
import { AppContext } from "../app-context";
import { cancelFunction } from "../utilities/helper";
import NodeDetailsForm from "./AddEditNodeForm";
import styles from "../styles/popup.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import stylesLegend from "../styles/dashboard.module.scss";

function Popup() {
  /**
   * State variables and context section
   */
  const context = useContext(AppContext);
  const [popup, setPopup] = useState(true);
  const [addNode, setAddNode] = useState(false);
  const [editNode, setEditNode] = useState(false);
  const [deleteNode, setDeleteNode] = useState(false);
  /**
   * addNodeHandler, editNodeHandler, deleteNodeHandler,cancelPopup functions definition section
   * All these functions help us to set a node action popup value as true or false.
   */
  function addNodeHandler() {
    setAddNode(!addNode);
    popup && setPopup(false);
  }
  function editNodeHandler() {
    setEditNode(!editNode);
    popup && setPopup(false);
  }
  function deleteNodeHandler() {
    setDeleteNode(!deleteNode);
    popup && setPopup(false);
  }
  function cancelPopup() {
    cancelFunction("popup", setEditNode, context, "-150px");
  }

  return (
    <>
      {addNode && (
        <div className={stylesLegend.legendsPopup}>
          <NodeDetailsForm
            popup={popup}
            isEdit={false}
            setPopup={setPopup}
            setAddNode={setAddNode}
            setEditNode={setEditNode}
          />
        </div>
      )}
      {editNode && (
        <div className={stylesLegend.legendsPopup}>
          <NodeDetailsForm
            popup={popup}
            isEdit={true}
            setPopup={setPopup}
            setAddNode={setAddNode}
            setEditNode={setEditNode}
          />
        </div>
      )}
      {deleteNode && (
        <div className={stylesLegend.legendsPopup}>
          <DeleteNode
            popup={popup}
            setPopup={setPopup}
            setDeleteNode={setDeleteNode}
          />
        </div>
      )}
      {popup && (
        <div
          id="popupAnimation"
          className={
            `${popup}` ? [styles.show].join(" ") : styles.popupContainer
          }
        >
          <div className={styles.popUpHeader}>
            <h1>Action</h1>
            <span
              className={[
                "material-symbols-rounded",
                "icons",
                fcCSS.cursorPtr,
              ].join(" ")}
              onClick={cancelPopup}
            >
              close
            </span>
          </div>
          <div className={styles.popUpBody}>
            <button className={styles.button} onClick={editNodeHandler}>
              <div
                className={[styles.buttonContainer, styles.editColor].join(" ")}
              >
                <span className="material-symbols-rounded icons">edit</span>
                <p className={styles.editNode}>Edit</p>
              </div>
              <span className="material-symbols-rounded icons">
                chevron_right
              </span>
            </button>
            <button className={styles.button} onClick={addNodeHandler}>
              <div
                className={[styles.buttonContainer, styles.addColor].join(" ")}
              >
                <span className="material-symbols-rounded icons">
                  add_circle
                </span>
                <p>Add</p>
              </div>
              <span className="material-symbols-rounded icons">
                chevron_right
              </span>
            </button>
            <button className={styles.button} onClick={deleteNodeHandler}>
              <div
                className={[styles.buttonContainer, styles.deleteColor].join(
                  " "
                )}
              >
                <span className="material-symbols-rounded icons">delete</span>
                <p className={styles.deleteNode}>Delete</p>
              </div>
              <span className="material-symbols-rounded icons">
                chevron_right
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
