import { apiParams, apiUrl } from "./api-constants";
import { getAxios, getUserAxios } from "./axios-config";
/**
 * userList function definition section
 * This function helps us to get/list the user information from microsoft graph api
 * @returns modified response
 */
export async function userList(accessToken: any) {
  const userApiResponse: any = await getUserAxios(
    apiUrl.users,
    {},
    accessToken
  );
  let userArray: any = [];
  userApiResponse.data.value.forEach((item: any) => {
    userArray.push({
      value: item.displayName,
      label: item.displayName,
      uu_ad_id: item.id,
      mail: item.mail,
    });
  });
  return userArray;
}
/**
 * getDepartmentsList function definition section
 * This function helps us to get/list the departments information from departments api
 * @param optFormat
 * @returns response
 */
export async function getDepartmentsList(accessToken: any) {
  try {
    const apiRes: any = await getAxios(
      apiUrl.departments,
      {
        code: apiParams.codeDepartment,
      },
      accessToken
    );
    return apiRes.data;
  } catch (error: any) {
    console.log("error", error);
  }
}
/**
 * getDepartmentsOpts function definition section
 * This function helps us to ge/list departments id and name from departments api
 * @param apiRes
 * @returns modified response
 */
export function getDepartmentsOpts(apiRes: any) {
  let dptArray: any = [];
  if (apiRes?.length > 0) {
    apiRes?.forEach((item: any) => {
      dptArray.push({ value: item._id, label: item.name });
    });
  }
  return dptArray;
}

/**
 * getParentPath function definition section
 * This function helps us to get selected parent node's name and its path
 * @param _id
 * @param nodeData
 * @returns parent path and parent node name
 */
export function getParentPath(_id: any, nodeData: any) {
  let parentPath: any = [];
  let patentName: string = "";
  nodeData?.forEach((item: any) => {
    if (item._id === _id) {
      parentPath.push(item.parent_path);
      patentName = item.node_name;
    }
  });
  return {
    parentPath,
    patentName,
  };
}

/**
 * parentPathOptions function definition section
 * This function helps us to set parent node options
 * @param _id
 * @param nodeData
 * @returns node options array
 */
export function parentPathOptions(_id: any, nodeData: any) {
  let pathArray: any = [];
  if (nodeData?.length > 0) {
    nodeData?.forEach((item: any) => {
      if (item._id !== _id) {
        pathArray.push({ value: item._id, label: item.node_name });
      }
    });
  }
  return pathArray;
}
