import React, { useContext, useEffect } from "react";
import { AppContext } from "../app-context";
import { useActivityLogService } from "../utilities/apiService";

const TabActivity: React.FC = () => {
  const context = useContext(AppContext);
  const callActivityLogAPI = useActivityLogService();

  useEffect(() => {
    const visibility = async () => {
      if (document.hidden) {
        await callActivityLogAPI("user_not_active");
      } else {
        await callActivityLogAPI("user_active");
      }
    };
    // document.addEventListener("beforeunload", visibility);
    document.addEventListener("visibilitychange", visibility);
    return () => {
      document.removeEventListener("visibilitychange", visibility);
    };
    // eslint-disable-next-line
  }, [context?.userToken?.accessToken]);

  return <></>;
};

export default TabActivity;
