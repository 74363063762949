import { useMsal } from "@azure/msal-react";
import { msalConfigParams } from "../api/api-constants";

export const useLogin = () => {
  const { instance } = useMsal();
  const login = () => {
    instance.loginRedirect({
      scopes: msalConfigParams.scopes,
    });
  };
  return login;
};

export const useLogout = () => {
  const { instance } = useMsal();
  const logout = () => {
    instance.logout();
  };
  return logout;
};

export const useIsAuthenticated = () => {
  const { accounts } = useMsal();

  const isAuthenticated = () => {
    return !!accounts[0];
  };
  return isAuthenticated;
};

export const useGetToken = () => {
  const { instance, accounts } = useMsal();

  const getToken = async () => {
    const response = await instance.acquireTokenSilent({
      scopes: msalConfigParams.scopes,
      account: accounts[0],
    });

    return response;
  };

  return getToken;
};
