import { useContext } from "react";
import { apiParams, apiUrl } from "../api/api-constants";
import { getAxios, postAxios } from "../api/axios-config";
import { AppContext } from "../app-context";
import moment from "moment";
import { useGetToken, useLogin } from "./authService";

export const handleAPIResponse = (respData: any) => {
  return respData;
};

export const useHandleHTTPStatus = () => {
  const getToken = useGetToken();
  const context = useContext(AppContext);
  const login = useLogin();
  const transformHttpStatusData = async (resp: any) => {
    if (resp.status) {
      switch (resp.status) {
        case 200:
          if (resp.data?.statusCode === 401) {
            getToken()
              .then((tokenResponse: any) => {
                if (tokenResponse.accessToken) {
                  context.setIsLoggedIn(true);
                  context.setUserToken(tokenResponse);
                } else {
                  localStorage.clear();
                  login();
                }
              })
              .catch((error: any) => {
                localStorage.clear();
                login();
              });
          } else {
            return handleAPIResponse(resp.data);
          }
          break;
        case 500:
        case 400:
        case 401:
          getToken()
            .then((tokenResponse: any) => {
              if (tokenResponse.accessToken) {
                context.setIsLoggedIn(true);
                context.setUserToken(tokenResponse);
              } else {
                localStorage.clear();
                login();
              }
            })
            .catch((error: any) => {
              localStorage.clear();
              login();
            });
          break;
        default:
          return resp.data;
      }
    }
  };
  return transformHttpStatusData;
};

export const useActivityLogService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callActivityLogAPI = async (status: any = "user_active") => {
    try {
      let body: any = {
        status: status,
        time: moment.utc(Date.now()).valueOf(),
      };

      return await postAxios(
        apiUrl.audit,
        { code: apiParams.codeAudit },
        body,
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callActivityLogAPI;
};

export const useConfigService = () => {
  const transformHttpStatusData = useHandleHTTPStatus();
  const callConfigAPI = async (params: any, accessToken: string) => {
    try {
      return await postAxios(
        apiUrl.config,
        { code: apiParams.codeConfig, ...params },
        {},
        accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callConfigAPI;
};

export const useListNodeService = () => {
  const context = useContext(AppContext);
  const transformHttpStatusData = useHandleHTTPStatus();
  const callListNodesAPI = async (params: any) => {
    try {
      return await getAxios(
        apiUrl.node,
        { code: apiParams.codeNode, ...params },
        context.userToken.accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callListNodesAPI;
};

export const useListActivityLogService = () => {
  const transformHttpStatusData = useHandleHTTPStatus();
  const callListActivityLogAPI = async (params: any, accessToken: any) => {
    try {
      return await getAxios(
        apiUrl.audit,
        { code: apiParams.codeAudit, ...params },
        accessToken
      ).then((resp: any) => {
        return transformHttpStatusData(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return callListActivityLogAPI;
};
