import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import "../styles/graph.module.scss";
import fcCSS from "../styles/flexcssable.module.scss";
import styles from "../styles/graph.module.scss";
import "../styles/globals.scss";
import Header from "./Header";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../app-context";
import { apiParams } from "../api/api-constants";
import { useListActivityLogService } from "../utilities/apiService";
import PageNotFound from "./404";
import SideMenu from "./SideMenu";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import moment from "moment";
import { NavLink } from "react-router-dom";
import useDeviceDetect from "./lib/_useDeviceDetector";
import Loader from "./Loader";
import { saveAs } from "file-saver";

const Graph = () => {
  const [graphData, setGraphData] = useState<any>({
    sessions_per_day: 0,
    users: 0,
    users_grouped_by_department: 0,
  });
  const callListActivityLogAPI = useListActivityLogService();
  const { isMobile } = useDeviceDetect();
  const [showFilter, setShowFilter] = useState(true);
  const [isReset, setIsReset] = useState(false);
  const [apiResponseLength, setApiResponseLength] = useState(0);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [newBarChartView, setNewBarChartView] = useState(false);
  const [newBarChart, setNewBarChart] = useState("");
  let isNewBarChartEnable = false;
  const [paramsObject, setParamsObject] = useState<any>({
    code: apiParams.codeAudit,
    view_by: "day",
    graph_view: true,
    start_date: moment(Date.now()).date(1).format("MM-DD-YYYY"),
    end_date: moment(Date.now()).format("MM-DD-YYYY"),
  });
  const context = useContext(AppContext);
  const view_by: any = {
    day: "Days",
    week: "Weeks",
    month: "Months",
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    {
      id: "custom_canvas_background_color",
      beforeDraw: (chart) => {
        const ctx = chart.canvas.getContext("2d");
        ctx?.save();
        ctx != null && (ctx.globalCompositeOperation = "destination-over");
        ctx != null && (ctx.fillStyle = "#ffffff");
        ctx?.fillRect(0, 0, chart.width, chart.height);
        ctx?.restore();
      },
    }
  );
  let month: any = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };
  /**
   * _handleFilter function definition section
   * This function helps us to trigger the getAuditApiResponse function when the filter action is required
   */
  const _handleFilter = async () => {
    if (newBarChartView) {
      document.getElementById("barChart1Focus")?.scrollIntoView();
    }
    context.setAppLoader(true);
    getAuditApiResponse();
  };
  /***
   * getAuditApiResponse function definition section
   * Here we're calling get audit api and setting response values to corresponding state variables
   */
  const getAuditApiResponse = async () => {
    await callListActivityLogAPI(
      { ...paramsObject, view_by: "day" },
      context.userToken.accessToken
    ).then((apiResponse: any) => {
      context.setAppLoader(false);
      if (apiResponse?.status) {
        setApiResponseLength(apiResponse.data?.length);
        if (apiResponse?.data && apiResponse?.data?.length > 0) {
          let activity_logs: any = apiResponse.data;
          let graph_data: any = {
            sessions_per_day: 0,
            users: [],
            users_by_view_type: {
              data: [],
              labels: [],
            },
            users_by_department: {
              data: {},
              labels: [],
            },

            users_by_department_per_day: {
              data_by_bar: [],
              data: [],
              labels: [],
            },
          };
          for (let i = 0; i < activity_logs?.length; i++) {
            graph_data.sessions_per_day += activity_logs[i].users.length;
            graph_data.users = [...graph_data.users, ...activity_logs[i].users];

            if (i === 0) {
              graph_data.users_by_view_type.labels = [];
              graph_data.users_by_view_type.data = [];
            }
            switch (paramsObject["view_by"]) {
              case "day":
                graph_data.users_by_view_type.labels.push(
                  moment(activity_logs[i].day, "DD-MM-YYYY").format(
                    "Do MMM, YY"
                  )
                );
                graph_data.users_by_view_type.data.push(
                  activity_logs[i].users.length
                );
                if (
                  newBarChart ===
                  moment(activity_logs[i].day, "DD-MM-YYYY").format(
                    "Do MMM, YY"
                  )
                ) {
                  document.getElementById("barChart3Focus")?.scrollIntoView();
                  graph_data.users_by_department_per_day.data_by_bar.push(
                    activity_logs[i]
                  );
                }
                break;
              case "week":
                let week_no = moment(activity_logs[i].day, "DD-MM-YYYY").week();

                if (
                  !graph_data.users_by_view_type.labels.includes(
                    `Week ${week_no}`
                  )
                ) {
                  graph_data.users_by_view_type.labels.push(`Week ${week_no}`);
                }

                if (!graph_data.users_by_view_type.data[week_no]) {
                  graph_data.users_by_view_type.data[week_no] =
                    activity_logs[i].users.length;
                } else {
                  graph_data.users_by_view_type.data[week_no] +=
                    activity_logs[i].users.length;
                }
                if (i + 1 === activity_logs.length) {
                  graph_data.users_by_view_type.data =
                    graph_data.users_by_view_type.data.filter(function () {
                      return true;
                    });
                }
                if (newBarChart === `Week ${week_no}`) {
                  document.getElementById("barChart3Focus")?.scrollIntoView();
                  graph_data.users_by_department_per_day.data_by_bar.push(
                    activity_logs[i]
                  );
                }
                break;
              case "month":
                let month_no: any = moment(activity_logs[i].day, "DD-MM-YYYY");
                for (let val in month) {
                  if (
                    !graph_data.users_by_view_type.labels.includes(
                      month_no.format("MMM, YYYY") || val
                    )
                  ) {
                    graph_data.users_by_view_type.labels.push(
                      month_no.format("MMM, YYYY")
                    );
                  }
                }
                if (!graph_data.users_by_view_type.data[month_no.month()]) {
                  graph_data.users_by_view_type.data[month_no.month()] =
                    activity_logs[i].users.length;
                } else {
                  graph_data.users_by_view_type.data[month_no.month()] +=
                    activity_logs[i].users.length;
                }

                if (i + 1 === activity_logs.length) {
                  graph_data.users_by_view_type.data =
                    graph_data.users_by_view_type.data.filter(function () {
                      return true;
                    });
                }
                if (newBarChart === month_no.format("MMM, YYYY")) {
                  document.getElementById("barChart3Focus")?.scrollIntoView();
                  graph_data.users_by_department_per_day.data_by_bar.push(
                    activity_logs[i]
                  );
                }
                break;
            }
          }
          graph_data.users = graph_data.users.filter(
            (value: any, index: any, self: any) => {
              return (
                index ===
                self.findIndex((t: any) => t.user_oid === value.user_oid)
              );
            }
          );
          // By department
          for (let j = 0; j < graph_data.users.length; j++) {
            let user = graph_data.users[j];
            if (user.department == null) {
              user.department = "000 Others";
            }
            if (
              !graph_data.users_by_department.labels.includes(user.department)
            ) {
              graph_data.users_by_department.labels.push(user.department);
            }
            let department: any = user.department;
            if (!graph_data.users_by_department.data[department]) {
              graph_data.users_by_department.data[department] = 1;
            } else {
              graph_data.users_by_department.data[department] += 1;
            }
          }
          graph_data.users_by_department.data = Object.values(
            graph_data.users_by_department.data
          );
          // new bar chart
          for (
            let i = 0;
            i < graph_data.users_by_department_per_day.data_by_bar.length;
            i++
          ) {
            for (
              let j = 0;
              j <
              graph_data.users_by_department_per_day.data_by_bar[i]?.users
                .length;
              j++
            ) {
              let user =
                graph_data.users_by_department_per_day.data_by_bar[i]?.users[j];
              if (user.department === null) {
                user.department = "000 Others";
              }
              if (
                !graph_data.users_by_department_per_day.labels.includes(
                  user.department
                )
              ) {
                graph_data.users_by_department_per_day.labels.push(
                  user.department
                );
              }

              let department: any = user.department;
              if (!graph_data.users_by_department_per_day.data[department]) {
                graph_data.users_by_department_per_day.data[department] = 1;
              } else {
                graph_data.users_by_department_per_day.data[department] += 1;
              }
            }
          }

          graph_data.users_by_department_per_day.data = Object.values(
            graph_data.users_by_department_per_day.data
          );
          graph_data.users_by_view_type = constructUsersByViewType(
            graph_data.users_by_view_type
          );
          graph_data.users_by_department = constructUsersByDepartment(
            graph_data.users_by_department
          );
          graph_data.users_by_department_per_day = constructUsersByDepartment(
            graph_data.users_by_department_per_day
          );
          setGraphData(graph_data);
          setNoResultsFound(false);
        } else {
          setGraphData({});
          setNoResultsFound(true);
        }
      } else {
        setNoResultsFound(true);
      }
    });
  };

  const constructUsersByViewType = (chart_data: any) => {
    let options: any = {
      barPercentage: 0.3,
      // indexAxis: "y" as const,
      animations: {
        radius: {
          duration: 400,
          easing: "linear",
          loop: (context: any) => context.active,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: view_by[paramsObject["view_by"]],
          },
          // max:
          //   Math.max(...chart_data.labels) +
          //   ((Math.max(...chart_data.labels) % 10) - 10) * -1,
          // min: 0,
        },
        y: {
          title: {
            display: true,
            text: "Users sessions count",
          },
          grace: "100%",
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "right",
        },
        title: {
          display: true,
        },
      },
      onClick: (event: any, elements: any) => {
        isNewBarChartEnable =
          elements[0] !== undefined
            ? !isNewBarChartEnable
            : isNewBarChartEnable;
        setNewBarChartView(isNewBarChartEnable);
        setNewBarChart(chart_data.labels[elements[0]?.index]);
      },
    };

    let data: any = {
      labels: chart_data?.labels,
      datasets: [
        {
          label: "Sessions per " + paramsObject["view_by"],
          data: chart_data?.data,
          backgroundColor: chart_data?.data.map((e: any) => {
            return "#165aaa";
          }),
        },
      ],
    };

    return { options, data };
  };

  const constructUsersByDepartment = (chart_data: any) => {
    let options: any = {
      barPercentage: 0.5,
      indexAxis: "y" as const,
      animations: {
        radius: {
          duration: 400,
          easing: "linear",
          loop: (context: any) => context.active,
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Departments",
          },
        },
        x: {
          title: {
            display: true,
            text: "Unique user count",
          },
          grace: "100%",
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: true,
        },
      },
    };

    let data: any = {
      labels: chart_data?.labels,
      datasets: [
        {
          label: "User count",
          data: chart_data?.data,
          backgroundColor: () => {
            if (chart_data?.data.length <= 40) {
              return [
                "#FF5733",
                "#A832E8",
                "#41B845",
                "#F78D12",
                "#6F28C7",
                "#C5E72F",
                "#E63A80",
                "#19D4A1",
                "#FC5B97",
                "#94F021",
                "#D83C3A",
                "#5ED9F8",
                "#D12E64",
                "#70BF49",
                "#F4A937",
                "#3F9C6D",
                "#ED4821",
                "#894EE5",
                "#E2C03D",
                "#763C93",
                "#A6E54B",
                "#F14489",
                "#58E287",
                "#F9A25F",
                "#8F42CA",
                "#E6F82D",
                "#BE2A60",
                "#32C5D8",
                "#E59826",
                "#4360AF",
                "#FFA2D1",
                "#C2F556",
                "#FF4F25",
                "#63BB7F",
                "#C92D98",
                "#0BA457",
                "#E72B1D",
                "#8E4AB7",
                "#E5D53F",
                "#6547BF",
              ];
            } else {
              return chart_data?.data.map((e: any) => {
                return "#" + Math.floor(Math.random() * 16777215).toString(16);
              });
            }
          },
          // backgroundColor: barColors?.map((item: any) => {
          //   return item;
          // }),
        },
      ],
    };

    return { options, data };
  };

  /**
   * _handlePageIsUserNotAdmin function definition section
   *  This function helps us to change the page UI based on user role and api status
   * @returns Loader and PageNotFound components
   */
  const _handlePageIsUserNotAdmin = () => {
    // if (!context.isAdmin) {
    //   return <PageNotFound />;
    // }
    if (apiResponseLength === 0) {
      return <Loader />;
    } else if (apiResponseLength > 0 && !context.isAdmin) {
      return <PageNotFound />;
    }
  };

  /**
   * _handleFilterReset function definition section
   * This function helps us to reset all the filters
   */
  const _handleFilterReset = () => {
    setParamsObject({
      graph_view: true,
      start_date: moment(Date.now()).date(1).format("MM-DD-YYYY"),
      end_date: moment(Date.now()).format("MM-DD-YYYY"),
      view_by: "day",
    });
    setIsReset(true);
  };

  const _handleExportCharts = (chartType: string) => {
    let chartName: any = {
      barChart1: `Active_users_by_date ${paramsObject["start_date"]} to ${paramsObject["end_date"]}`,
      barChart2: `Active_users_per_department_Bar_Chart_view ${paramsObject["start_date"]} to ${paramsObject["end_date"]}`,
      barChart3: `Active_users_per_department_in_${newBarChart}`,
      pieChart1: `Active_users_per_department_Pie_Chart_view ${paramsObject["start_date"]} to ${paramsObject["end_date"]}`,
    };
    const canvasSave: any = document.getElementById(chartType);
    canvasSave.toBlob(function (blob: any) {
      saveAs(blob, `${chartName[chartType]}.jpg`);
    });
  };

  useEffect(() => {
    context.setAppLoader(true);
    context.setSideMenuBar(false);
    context.setIsSearchBoxOpen(false);
    context.setExpandAllNodes(false);
    setIsReset(false);
    _handleFilter();
    if (isMobile) {
      setTimeout(() => {
        setShowFilter(false);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [isReset, newBarChart]);
  return (
    <>
      {context.isAdmin ? (
        <div className={"rootWrapper"}>
          <Header />
          {context.sideMenuBar && <SideMenu />}
          <div
            className={[
              styles.parent,
              context.isGraphView ? styles.graphViewBg : "",
            ].join(" ")}
          >
            <div className={[fcCSS.section1280].join(" ")}>
              <div className="pageTitleBar">
                <div className={[styles.chartTitle, fcCSS.flex].join(" ")}>
                  <h3>Activity Log</h3>
                  <NavLink to="/activities">
                    <button
                      className={[
                        "bgLessBlueBtn",
                        fcCSS.flex,
                        fcCSS.flexAlignItemsCenter,
                      ].join(" ")}
                    >
                      <>
                        Data View
                        <span
                          className={[
                            "material-symbols-rounded icons",
                            fcCSS.mrgnleft5,
                          ].join(" ")}
                        >
                          table
                        </span>
                      </>
                    </button>
                  </NavLink>
                </div>
              </div>
              <div
                className={[styles.graphContainer, fcCSS.padbtm60].join(" ")}
              >
                <div
                  className={[
                    fcCSS.flex,
                    fcCSS.flexResponsive,
                    fcCSS.width100,
                    fcCSS.bgWhite,
                    fcCSS.gap15px,
                  ].join(" ")}
                >
                  <div
                    className={[
                      fcCSS.width85,
                      fcCSS.pad10,
                      fcCSS.bdrRadius10px,
                      fcCSS["bdr-dddddd"],
                    ].join(" ")}
                  >
                    <div className={[styles.chartTitle].join(" ")}>
                      <h3>
                        Total active sessions per{" "}
                        {paramsObject["view_by"].charAt(0).toUpperCase() +
                          paramsObject["view_by"].slice(1)}
                      </h3>
                      <button
                        className={["btn"].join(" ")}
                        onClick={() => _handleExportCharts("barChart1")}
                      >
                        Export
                      </button>
                    </div>
                    <div id="barChart1Focus">
                      {!noResultsFound ? (
                        <>
                          {graphData?.users_by_view_type?.options && (
                            <Bar
                              id="barChart1"
                              options={graphData?.users_by_view_type?.options}
                              data={graphData?.users_by_view_type?.data}
                            />
                          )}
                        </>
                      ) : (
                        <div className="emptyState">
                          <div>
                            <div className="image"></div>
                            <div className="title">No Activities Recorded</div>
                            <div className="caption">
                              Please come back after sometime
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={[
                      fcCSS.width15,
                      fcCSS.flexAlignItemsCenter,
                      fcCSS.flexJustifyCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        fcCSS.bdrRadius10px,
                        fcCSS["bdr-dddddd"],
                        fcCSS.pad1020,
                        fcCSS.txtCenter,
                      ].join(" ")}
                    >
                      <h4>Total daily sessions</h4>
                      <p className={[fcCSS.font32].join(" ")}>
                        {graphData.sessions_per_day ?? 0}
                      </p>
                    </div>
                    <div className={[fcCSS.pad10].join(" ")}></div>
                    <div
                      className={[
                        fcCSS.bdrRadius10px,
                        fcCSS["bdr-dddddd"],
                        fcCSS.pad1020,
                        fcCSS.txtCenter,
                      ].join(" ")}
                    >
                      <h4>Total unique users</h4>
                      <p className={[fcCSS.font32].join(" ")}>
                        {graphData?.users?.length ?? 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div id="barChart3Focus">
                  {newBarChartView &&
                    graphData?.users_by_department_per_day?.data?.labels
                      .length > 0 && (
                      <div
                        className={[
                          fcCSS.width100,
                          fcCSS.pad10,
                          fcCSS.bdrRadius10px,
                          fcCSS["bdr-dddddd"],
                          fcCSS.mrgntop15,
                        ].join(" ")}
                      >
                        <div className={[styles.chartTitle].join(" ")}>
                          <h3>Active users per department in {newBarChart}</h3>
                          <button
                            className={["btn"].join(" ")}
                            onClick={() => _handleExportCharts("barChart3")}
                          >
                            Export
                          </button>
                        </div>

                        <Bar
                          id="barChart3"
                          options={
                            graphData?.users_by_department_per_day?.options
                          }
                          data={graphData?.users_by_department_per_day?.data}
                        />
                      </div>
                    )}
                </div>

                <div
                  className={[
                    fcCSS.width100,
                    fcCSS.bgWhite,
                    fcCSS.flex,
                    fcCSS.flexResponsive,
                    fcCSS.gap15px,
                    fcCSS.mrgntop15,
                  ].join(" ")}
                >
                  <div
                    className={[
                      fcCSS.width45,
                      fcCSS.pad10,
                      fcCSS.bdrRadius10px,
                      fcCSS["bdr-dddddd"],
                    ].join(" ")}
                  >
                    <div className={[styles.chartTitle].join(" ")}>
                      <h3>Active users per department</h3>
                      <button
                        className={["btn"].join(" ")}
                        onClick={() => _handleExportCharts("barChart2")}
                      >
                        Export
                      </button>
                    </div>

                    {!noResultsFound ? (
                      <>
                        {graphData?.users_by_department?.options && (
                          <Bar
                            id="barChart2"
                            height="400px"
                            options={graphData?.users_by_department?.options}
                            data={graphData?.users_by_department?.data}
                          />
                        )}
                      </>
                    ) : (
                      <div className="emptyState">
                        <div>
                          <div className="image"></div>
                          <div className="title">No Activities Recorded</div>
                          <div className="caption">
                            Please come back after sometime
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={[
                      fcCSS.width55,
                      fcCSS.pad10,
                      fcCSS.bdrRadius10px,
                      fcCSS["bdr-dddddd"],
                    ].join(" ")}
                  >
                    <div className={[styles.chartTitle].join(" ")}>
                      <h3>Active users per department</h3>
                      <button
                        className={["btn"].join(" ")}
                        onClick={() => _handleExportCharts("pieChart1")}
                      >
                        Export
                      </button>
                    </div>
                    {!noResultsFound ? (
                      <>
                        {graphData?.users_by_department?.options && (
                          <Pie
                            id="pieChart1"
                            data={graphData?.users_by_department?.data}
                          />
                        )}
                      </>
                    ) : (
                      <div className="emptyState">
                        <div>
                          <div className="image"></div>
                          <div className="title">No Activities Recorded</div>
                          <div className="caption">
                            Please come back after sometime
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!context.appLoader && (
            <>
              <div
                className={[
                  styles.filterContainer,
                  fcCSS.frostedGlassWhiteLiteBg,
                ].join(" ")}
              >
                {isMobile && (
                  <button
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    className={["btn", fcCSS.posSticky, fcCSS.posRight].join(
                      " "
                    )}
                  >
                    <span className="material-symbols-rounded icons">
                      filter_alt
                    </span>
                  </button>
                )}
                {showFilter && (
                  <div
                    className={[
                      fcCSS.flex,
                      fcCSS.flexResponsive,
                      fcCSS.gap15px,
                      fcCSS.section1280,
                    ].join(" ")}
                  >
                    <div
                      className={[styles.filterInput, fcCSS.width100].join(" ")}
                    >
                      <div>
                        <h5>Date Range :</h5>
                      </div>
                      <DatePicker
                        range
                        editable={false}
                        placeholder="Choose"
                        format="MM-DD-YYYY"
                        onChange={(dates: any) => {
                          dates !== null &&
                            dates[0] !== "" &&
                            setParamsObject({
                              ...paramsObject,
                              start_date: dates[0],
                              end_date: dates[1],
                            });
                        }}
                        value={[
                          paramsObject["start_date"],
                          paramsObject["end_date"],
                        ]}
                        // dateSeparator=" to "
                        className={[styles.dateRangeInput].join(" ")}
                      />
                    </div>
                    <div
                      className={[
                        styles.filterInput,
                        styles.viewByInputWidth,
                        fcCSS.width100,
                      ].join(" ")}
                    >
                      <div>
                        <h5>View By :</h5>
                      </div>
                      <div>
                        <Select
                          defaultValue={null}
                          onChange={(event: any) =>
                            setParamsObject({
                              ...paramsObject,
                              view_by: event.value,
                            })
                          }
                          options={[
                            { value: "day", label: "Days" },
                            { value: "week", label: "Weeks" },
                            { value: "month", label: "Months" },
                          ]}
                          styles={{
                            indicatorSeparator: () => ({ display: "none" }),
                            singleValue: (base: any) => ({
                              ...base,
                              paddingRight: "10px",
                              color: "hsl(0, 0%, 20%)",
                              cursor: "pointer",
                            }),
                          }}
                          placeholder={view_by[paramsObject["view_by"]]}
                          menuPlacement="top"
                        />
                      </div>
                    </div>

                    <div
                      className={[
                        styles.filterInput,
                        fcCSS.flex,
                        fcCSS.flexAlignItemsCenter,
                        fcCSS.mrgntop15,
                      ].join(" ")}
                    >
                      <button
                        onClick={_handleFilter}
                        className={["btn"].join(" ")}
                      >
                        <span className="material-symbols-rounded icons">
                          filter_list
                        </span>
                        Filter
                      </button>
                      <div className={fcCSS.pad5}></div>
                      <button
                        onClick={_handleFilterReset}
                        className="bgLessRedBtn"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>{_handlePageIsUserNotAdmin()}</div>
      )}
    </>
  );
};
export default Graph;
