import { useState } from "react";
import { AppContext } from "./app-context";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./utilities/ProtectedRoute";
import Dashboard from "./component/Dashboard";
import ActivityLog from "./component/ActivityLog";
import PageNotFound from "./component/404";
import TabActivity from "./component/TabActivity";
import Login from "./component/Login";
import Compare from "./component/Compare";
import Graph from "./component/Graph";
// import Graph from "./component/Graph";
export default function App() {
  /**
   * State variables section
   * In return, we are setting up a context provider and giving variables which we can access globally in our application.
   */
  const [nodeActionPopupFlag, setNodeActionPopupFlag] = useState(false);
  const [nodeData, setNodeData] = useState([]);
  const [activeUserAccount, setActiveUserAccount] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [legendsListClose, setLegendsListClose] = useState(false);
  const [userToken, setUserToken] = useState({});
  const [alertData, setAlertData] = useState({});
  const [highlightNode, setHighlightNode] = useState("");
  const [expandAllNodes, setExpandAllNodes] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [settings, setSettings] = useState([]);
  const [sideMenuBar, setSideMenuBar] = useState(false);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [appLoader, setAppLoader] = useState(false);
  const [searchListingNodes, setSearchListingNodes] = useState([]);
  const [treeType, setTreeType] = useState("organization");
  const [isPageReload, setIsPageReload] = useState(false);
  const [compareListingNodes, setCompareListingNodes] = useState([]);
  const [compareNodeData, setCompareNodeData] = useState([]);
  const [nodeLevel, setNodeLevel] = useState(0);
  const [nodeApiResponse, setNodeApiResponse] = useState([]);
  const [isAlertPopUp, setIsAlertPopUp] = useState(false);

  return (
    <AppContext.Provider
      value={{
        nodeActionPopupFlag,
        setNodeActionPopupFlag,
        nodeData,
        setNodeData,
        activeUserAccount,
        setActiveUserAccount,
        isLoggedIn,
        setIsLoggedIn,
        isAdmin,
        setIsAdmin,
        legendsListClose,
        setLegendsListClose,
        userToken,
        setUserToken,
        alertData,
        setAlertData,
        highlightNode,
        setHighlightNode,
        expandAllNodes,
        setExpandAllNodes,
        departmentList,
        setDepartmentList,
        settings,
        setSettings,
        sideMenuBar,
        setSideMenuBar,
        isSearchBoxOpen,
        setIsSearchBoxOpen,
        appLoader,
        setAppLoader,
        searchListingNodes,
        setSearchListingNodes,
        treeType,
        setTreeType,
        isPageReload,
        setIsPageReload,
        compareListingNodes,
        setCompareListingNodes,
        compareNodeData,
        setCompareNodeData,
        nodeLevel,
        setNodeLevel,
        nodeApiResponse,
        setNodeApiResponse,
        isAlertPopUp,
        setIsAlertPopUp,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/activities"
            element={
              <ProtectedRoute>
                <ActivityLog />
              </ProtectedRoute>
            }
          />
          <Route
            path="/activities/graph"
            element={
              <ProtectedRoute>
                <Graph />
              </ProtectedRoute>
            }
          />
          <Route
            path="/compare"
            element={
              <ProtectedRoute>
                <Compare />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/graph"
            element={
              <ProtectedRoute>
                <Graph />
              </ProtectedRoute>
            }
          /> */}
          {/* <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/404"
            element={
              <ProtectedRoute>
                <PageNotFound />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
      <TabActivity />
    </AppContext.Provider>
  );
}
